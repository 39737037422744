import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageDocument } from '@readme/backend/models/page/types';

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ProjectContext } from '@core/context'; // eslint-disable-line no-restricted-imports
import useMediaQuery from '@core/hooks/useMediaQuery';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import { useProjectStore, useSuperHubStore } from '@core/store';
import classy from '@core/utils/classy';

import SidebarList from './List';
import classes from './style.module.scss';
import './style.scss';
import useScrollToActiveSidebarItem from './useScrollToActiveSidebarItem';

export type SidebarPage = GitSidebarPage | PageDocument;

export interface SidebarCategory {
  pages: SidebarPage[];
  slug?: string;
  title: string;
  uri?: string;
}

export interface SidebarProps {
  /**
   * Slug for the current page.
   */
  activeDoc?: string;

  /**
   * Collection of ReadMe docs or endpoint reference pages.
   */
  categories: SidebarCategory[];

  className?: string;

  /**
   * Optional custom icon render function for the sidebar list items.
   */
  customIconRender?: (pageType: string | undefined, title: string) => JSX.Element | null;

  /**
   * Optional sidebar header.
   */
  header?: React.ReactNode;

  /**
   * Optional id to be applied to the <nav> component.
   */
  id?: string;

  /**
   * Optional CSS class to be applied to the <nav> component.
   */
  navClass?: string;

  /**
   * Sidebar link prefix.
   */
  pathRoot?: string;
}

const ModuleDisabled = () => (
  <div className="disabled-warning">
    <i aria-hidden="true" className="icon icon-alert-circle" /> Only admins can see this
    <Link rel="noreferrer" target="_blank" to="/dash?to=usability#modules">
      Enable it for everyone <i aria-hidden="true" className="fa fa-chevron-right" />
    </Link>
  </div>
);

const PoweredBy = () => {
  const { project } = useContext(ProjectContext);
  const { subdomain } = project;
  return (
    <div className="readme-logo" id="readmeLogo">
      Powered by&nbsp;
      <a aria-label="ReadMe" href={`https://readme.com?ref_src=hub&project=${subdomain}`} style={{ color: 'unset' }}>
        <i className="icon icon-readme" style={{ display: 'inline-block', minWidth: 71 }} />
      </a>
    </div>
  );
};

const IPAD_MINI_PORTRAIT = '768px';

const Sidebar = ({
  activeDoc,
  categories,
  className,
  customIconRender,
  header,
  pathRoot,
  navClass = '',
  id,
}: SidebarProps) => {
  const { project } = useContext(ProjectContext);
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const hideReadmeLogo = useProjectStore(s => s.data.appearance.footer.readme_logo === 'hide');
  const { scrollAreaRef, scrollContentRef, activeItemRef } = useScrollToActiveSidebarItem();
  const isMobile = useMediaQuery(`(max-width: ${IPAD_MINI_PORTRAIT})`);

  return (
    <nav
      ref={scrollAreaRef}
      aria-label="Page navigation"
      className={classy(
        'rm-Sidebar',
        'hub-sidebar',
        'reference-redesign',
        className,
        classes.Nav,
        pathRoot === 'docs' && ' rm-Sidebar_guides',
        navClass && navClass,
        isMobile && classes.Sidebar_flyout,
      )}
      id={id || 'hub-sidebar'}
    >
      {header}
      <div ref={scrollContentRef} className={`${classes.Sidebar} rm-Sidebar hub-sidebar-content`}>
        {!isSuperHub && !(project?.modules?.[pathRoot || ''] || pathRoot === 'edit') && <ModuleDisabled />}
        {Array.isArray(categories) &&
          categories
            .filter(category => category.pages.some(page => !page.hidden))
            .map(({ title, pages, slug, uri }) => (
              <section key={slug ?? uri} className={`${classes['Sidebar-listWrapper']} rm-Sidebar-section`}>
                <h2 className={`${classes['Sidebar-heading']} rm-Sidebar-heading`}>{title}</h2>
                <SidebarList
                  activeItemRef={activeItemRef}
                  alwaysExpanded={isMobile}
                  {...{ activeDoc, customIconRender, pathRoot, pages }}
                />
              </section>
            ))}
        {!(usePlanPermissions(project?.plan, 'whitelabel') || hideReadmeLogo) && <PoweredBy />}
      </div>
    </nav>
  );
};

export default React.memo(Sidebar);
